@import "../../../styles/variables";

.popup {
  // Rest Stops panel
  &--reststop {
    .popup__title {
      background-color: $Surface-reststop;
      border-top: 4px solid $Type-reststop;

      &__icon {
        background: $Type-reststop;
      }

      .name {
        color: $Type-reststop;
      }
    }

    .popup__content {
      &__title {

        .name {
          margin-bottom: 0;
          font-size: 1.5rem;
          color: $Type-Primary;

          &:first-letter {
            text-transform: capitalize;
          }
        }

        .location {
          color: $Type-Secondary;
          font-size: 0.875rem;
          margin-top: 0;
          letter-spacing: 0.075em;
        }
      }

      &__description {
        .description-label {
          font-weight: 700;
          margin-bottom: 0;
        }

        &__row {
          display: flex;
          align-items: flex-start;
          justify-content: space-between;
        }

        &__container {
          padding: 0 1rem;

          &__row {
            display: flex;
            align-items: flex-start;
            margin-top: 0.75rem;

            svg {
              margin-top: 2px;
              margin-right: 20px;
              color: $BC-Blue;
            }

            p {
              margin-bottom: 0;
              font-size: 0.875rem;
            }

            .toilets {
              text-transform: lowercase;
            }

            .count {
              margin-left: auto;
              font-weight: 700;
            }
          }
        }
        .tooltip-vehicles {
          color: $Type-Primary;
          margin-left: 8px;
          margin-right: 0;
          font-size: 0.875rem;
          border: none;
          background: none;
          padding: 0;
        }
      }

      &--row {
        display: flex;
      }
    }

    hr {
      margin-bottom: 0;
      border-top: 1px solid $Divider;
    }
  }
}
