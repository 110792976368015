body, p, h1, h2, h3, h4, a, span {
  font-family: 'BCSans', 'Noto Sans', Verdana, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 16px;
  font-weight: 400;
}

p {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6;
  color: $Type-Primary;
  max-width: 740px;
}

h1 {
  font-size: 2.25rem;
  font-weight: 700;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
}

h3 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 36px;
}

h4 {
  font-size: 1.25rem;
  font-weight: 700;
  color: $BC-Blue;
}

a {
  color: $Type-Link;
  font-weight: 400;
  
  &:hover {
    text-decoration: underline;
  }
}

ul {
  line-height: 1.6;
}

.label {
  color: $Type-Primary;
  font-size: 0.875rem;
  font-weight: 400;
}

.body {
  font-size: 1rem;
  font-weight: 400;
}

.body--large {
  font-size: 1.125rem;
  font-weight: 400;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: 400;
}

.subtitle--large {
  font-size: 1.5rem;
  font-weight: 400;
}

.display {
  font-size: 3rem;
  font-weight: 700;
}

.bold {
  font-weight: 700;
}

.hero {
  color: $BC-Blue;
}

.green-text {
  color: $Success;
}

.red-text {
  color: $Error;
}

.blue-text {
  color: $BC-Blue;
}

.lowercase {
  text-transform: lowercase;
}

ol, ul {
  padding-left: 1.5rem;
  color: $Type-Primary;
}