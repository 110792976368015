@import "../../../../styles/variables";

.panel-content {
  overflow-x: hidden;
}

.carousel-container {
  padding: 1rem 0;
  position: relative;

  .GoodCarousel {
    overflow: visible;

    .GoodCarousel-inner {
      .GoodCarousel-item {
        position: relative;

        div:first-of-type {
          border-radius: 8px;
          padding: 0.75rem;
          height: 100%;
          &:before {
            display: block;
            content: "";
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            position: absolute;
            border-radius: 8px;
            border: 1px solid var(--colors-bc-theme-greys-20, #F3F2F1);
            box-shadow: 0px 6.8px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.10);
            opacity: 1;  
            transition: 0.4s ease-in-out all;
          }

          &.inactive {
            transition: 0.1s box-shadow ease-in-out;
            border: 0;
            &:before {
              display: block;
              content: "";
              width: 100%;
              height: 100%;
              left: 0;
              top: 0;
              position: absolute;
              border-radius: 8px;
              border: 1px solid var(--colors-bc-theme-greys-20, #F3F2F1);
              box-shadow: 0px 6.4px 14.4px 0px rgba(0, 0, 0, 0.13), 0px 1.2px 3.6px 0px rgba(0, 0, 0, 0.10);
              opacity: 0;
              transition: 0.4s ease-in-out opacity;
            }
          }
        }
 

        .carousel-header-container {
          display: flex;
          justify-content: space-between;
          margin: 0.25rem 0 0.5rem 0;

          .forecast-name {
            font-size: 1rem;
            font-weight: 700;
          }

          .forecast-temp-text {
            font-size: 0.875rem;
          }

          .weather-icon {
            margin-right: 1rem;
          }
        }

        .forecast-text-summary {
          font-size: 0.875rem;
        }

        .inactive {
          opacity: 0.3;
        }
      }
    }
  }

  .carousel-button {
    position: absolute;
    top: calc(50% - (35px / 2));

    border: 1px solid $Input-border;
    border-radius: 2rem;

    background: #FFFFFF;
    color: $Type-Primary;
    box-shadow: 0px 3.2px 7.2px 0px #00000021, 0px 0.6px 1.8px 0px #0000001A;

    &:focus, &:active, &:hover {
      background-color: $Grey40;
      color: $Type-Primary;
      border: 1px solid $Input-border;
    }

    &.next {
      right: 0;
      animation: show 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;
    }

    &.prev {
      left: 0;
      animation: show 600ms 100ms cubic-bezier(0.38, 0.97, 0.56, 0.76) forwards;      
    }
  }
}

@keyframes show {
  100% {
    opacity: 1;
    transform: none;
  }
}

