@import "../styles/variables.scss";

.container--sidepanel {
  display: flex;
  flex-direction: column;

  &.container {
    padding-top: 24px;
  }

  @media (min-width: 576px) {
    flex-direction: row;
  }

  &__left {
    margin-right: 28px;
    padding-bottom: 24px;
    width: 360px;
    min-width: 360px;
    position: sticky;
    top: 82px;
    align-self: flex-start;

    @media (min-width: 1200px) {
      max-height: calc(100vh - 8vh);
      overflow-y: auto;

      &::-webkit-scrollbar {
       display: none;
      }
    }

    .routing {
      margin-bottom: 20px;
      padding: 4px 10px 4px 4px;

      @media (min-width: 1200px) {
        width: 100%;
      }

      .routing-container {
        position: relative;
        padding: 0;

        .typeahead-container {
          width: 100%;
        }

        .swap-button{
          position: absolute;
          top: 22px;
          right: -10px;
        }
      }
    }

    .advisories {
      margin-top: 36px;
      padding-right: 10px;

      .title {
        background: $Gold30;
        padding: 12px 16px;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        h4 {
          margin-bottom: 0;
          font-weight: 400;
          line-height: 2.125rem;
          color: $Gold110;
        }
      }

      .content {
        background: $Grey10;
        padding: 16px 16px 32px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        .advisories-list {
          list-style: none;

          .friendly-time {
            margin-left: 0;
          }

          .advisory-li {
            margin-left: 0;
            color: $Type-Link;
            display: flex;
            &:hover {
              background-color: $Grey10;
              transition: 0.1s ease-in-out background-color;        
            }
            &::marker {
              color: $Type-Link;
            }
            .friendly-time-text {
              margin-left: 0;
            }
            
            .advisory-li-teaser {
              max-width: 95%;
            }

            .advisory-li-title-container {
              .advisory-li-title {
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
  }

  &__right {
    flex: 1 1 auto;

    .controls-container {
      justify-content: space-between;

      .tools-container {
        display: flex;
        align-items: center;
        position: relative;
  
        .btn {
          margin-bottom: 0;
        }

        .filter-option-btn {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 16px;
          
          @media (max-width: 1199px) {
            padding: 0;
            height: 36px;
            width: 36px;
            border: none;
          }

          &.active {
            background: $Info;
            color: $White;

            .btn-text {
              color: $White;
            }
          }
        }

        .highway-filter-btn {

          &__icon {
            @media (min-width: 1200px) {
              margin-left: 8px;
              margin-right: 8px;
            }
          }

          .btn-text {
            font-size: 0.875rem;
            margin-bottom: 0;
          }

          .dropdown-icon {
            transition: transform .3s;
          }

          &.active {
            .dropdown-icon {
              transition: transform .3s;
              transform:rotate(180deg);
            }
          }

          &.filtered {
            background: $Info;
            color: $White;

            .btn-text {
              color: $White;
            }
          }
        }
      }

      .advisories-btn {
        display: flex;
        align-items: center;
        margin-left: 16px;

        .advisories-count {
          margin: 0;
        }
      }

      .findRoute-btn {
        font-size: 0.875rem;
        height: 36px;
        margin: auto 0;
        white-space: nowrap;

        @media (min-width: 768px) {
          margin: auto 0;
        }

        &:hover {
          border: 1px solid $Grey100;
        }

        svg {
          margin-right: 4px;
        }
      }

      .routing {
        padding: 0;
      }
    }

    .camSearch-container--mobile {
      display: flex;
      height: 0;
      overflow: hidden;
      transition: height 0.2s ease-in-out;
      margin-bottom: 16px;

      &.open {
        height: 36px;
        overflow: auto;
        transition: height 0.2s ease-in-out;
      }

      .rbt {
        flex-shrink: 0;
        flex-grow: 1;

        .rbt-input {
          height: 36px;
        }
      }

      .close-camera-search-btn {
        color: $Grey110;
        background: none;
        border: none;
        margin: 2px;
        padding: 4px 10px;
      }
    }
  }
}
