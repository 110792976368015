@import "../../styles/variables.scss";
@import '~ol/ol.css';

.report-page {

  @media (max-width: 767px) {
    display: flex;
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100 - 58px);
  }

  .page-header__content, .page-subtitle, .report-map-wrap {
    transition: all 0.20s ease-in-out;

    & > h1, p {
      height: auto;
      transition: all 0.20s ease-in-out;
    }
  }

  @media (max-width: 767px) {
    &:has(.side-panel.open), &:has(.report-map-container.expanded) {
      .page-header__content, .page-subtitle {
        grid-template-rows: 0fr;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        transition: all 0.20s ease-in-out;

        div, h1, p {
          overflow: hidden;
          margin: 0;
          height: 0;
          transition: all 0.20s ease-in-out;
        }
      }

      .report-map-wrap {
        height: calc(var(--vh, 1vh) * 100 - 58px);
      }
    }

    &:has(.report-map-container.expanded) {
      .page-header .container {
        grid-template-rows: 0fr;
        padding-top: 0;
        padding-bottom: 0;
        overflow: hidden;
        transition: all 0.20s ease-in-out;

        .back-link-wrap {
          overflow: hidden;
        }
      }
    }

    .page-header .container, .page-header__content, .page-subtitle {
      display: grid;
      grid-template-rows: 1fr;
    }
  }

  .page-header {
    .back-link-wrap {

      .back-link {
        font-weight: 700;
        text-decoration: none;
        cursor: pointer;
        margin-bottom: 10px;

        svg {
          margin-right: 8px;
        }
      }
    }

    .page-title {
      margin-top: 10px;
    }

    .page-description {
      max-width: 550px;
      margin-bottom: 0;
    }
  }

  .page-subtitle {
    padding-top: 16px;
    padding-bottom: 16px;

    p {
      margin-bottom: 0;
    }
  }

  // Z-index
  .ol-overlaycontainer-stopevent {
    z-index: 20 !important;

    .ol-overlay-container {
      z-index: 10;
    }

    .ol-scale-line {
      z-index: 5;
    }
  }

  .zoom-btn, .my-location {
    z-index: 5;
  }

  .report-map-wrap {
    padding-top: 0;
    padding-bottom: 0;
    height: auto;

    @media (max-width: 767px) {
      padding: 0;
      flex: 1 1 200px;
    }

    .report-map-container {
      display: flex;
      height: 720px;
      border: 1px solid $Divider;

      @media (max-width: 767px) {
        flex-direction: column;
        border: none;
        height: 100%;
      }

      .side-panel {
        height: 100%;
        background-color: white;
        flex: 0;
        min-width: 0px;
        min-height: 0%;
        transition: min-width 0.25s ease-in-out;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;

        @media (max-width: 767px) {
          transition: min-height 0.25s ease-in-out;
          order: 2;
          overflow: scroll;
        }

        &.open {
          min-width: 390px;

          .panel-content {
            min-width: 390px;
          }

          @media (max-width: 767px) {
            min-width: 100%;
            min-height: 50%;

            .panel-content {
              min-width: 100%;
            }
          }
        }

        &.maximized {
          @media (max-width: 767px) {
            min-height: 100%;
            overflow: auto;
          }
        }

        .close-panel {
          position: absolute;
          top: 2rem;
          right: 1rem;
          padding: 0;
          min-width: 0;
          line-height: 1.25rem;
          height: 2rem;
          width: 2rem;
          border-radius: 50%;
          background: none;
          border: none;
          font-size: 1rem;
          color: $White;
          z-index: 1000;

          &:hover {
            background: $Type-Link;
          }
        }

        .panel-content {
          flex: 1;

          .popup {
            &__title {
              background-color: $Info;
              border-top: 4px solid $Info;
              padding: 1rem 1rem 0.5rem;

              &__icon {
                background-color: $Type-Link;
              }

              .name {
                color: $White;
              }
            }

            &__content {
              padding: 1rem;

              .service-area {
                font-size: 1.5rem;
                font-weight: 700;
                color: $Info;
                margin-bottom: 0;
              }

              .service-area-number {
                color: $Info;
              }

              .contractor-name {
                margin-bottom: 1rem;

                .website-link {
                  color: $Grey100;
                  font-weight: 700;
                }

                .without-link {
                  color: $Grey100;
                  font-weight: 700;
                }
              }

              .contractor-description {
                font-size: 0.875rem;
              }

              .contractor-phone {
                display: flex;
                align-items: center;
                margin-bottom: 1rem;
                color: $Type-Link;
                font-weight: 700;

                svg {
                  margin-right: 8px;
                }
              }
            }
          }
        }
      }

      .report-map {
        flex: 1;
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .ol-overlay-container:not(:has(> img)) {
          //overriding openlayers dynamic styles for mobile layout except for having img as a direct child
          @media (max-width: 767px) {
            position: fixed !important;
            bottom: 0 !important;
            transform: none !important;
          }

          // overlay content styling moved to App.scss
        }

        .ol-zoom .ol-zoom-out {
          margin-top: 200px;
        }

        .ol-scale-line {
          background: var(--ol-partial-background-color);
          border-radius: 4px;
          left: auto;
          right: 1rem;
          bottom: 4rem;
          padding: 2px;
          position: absolute;

          @media (min-width: 769px) {
            right: 1rem;
            bottom: 1rem;
          }

          @media (min-width: 992px) {
            right: 4rem;
            bottom: 1rem;
          }
        }

        .ol-scale-line-inner {
          border: 1px solid var(--ol-subtle-foreground-color);
          border-top: none;
          color: var(--ol-foreground-color);
          font-size: 0.75rem;
          text-align: center;
          font-family: 'BCSans', serif;
          margin: 1px;
          will-change: contents, width;
          transition: all 0.25s;
        }
      }

      .map-btn {
        background-color: $Surface-tinted;
        color: $Type-Link;
        position: absolute;
        z-index: 1;
        margin-bottom: 0;
        font-size: 0.875rem;
        font-weight: 700;
        height: auto;
        border-radius: 4px;
        border: 1px solid $Type-Link;

        &:hover {
          background: $Type-Link;
          color: $White;
        }

        &.my-location, &.expand {
          svg {
            margin-right: 8px;
          }
        }

        &.my-location {
          position: absolute;
          bottom: 1rem;
          left: 1rem;
        }
      }

      .expand {
        right: 1rem;
      }

      .expand.minimized {
        top: 1rem;
    }

      .expand.expanded {
        top: 13rem;
    }

      .zoom-btn {
        background-color: $Surface-tinted;
        color: $Type-Link;
        position: absolute;
        margin-bottom: 0;
        font-size: 1rem;
        font-weight: 700;
        height: auto;
        border-radius: 6px;
        border: 1px solid $Type-Link;
        box-shadow: 0px 1.9368422031402588px 4.3578948974609375px 0px #00000021;
        bottom: 1rem;
        right: 1rem;
        display: flex;
        flex-direction: column;

        //Hide zoom slider on touch devices
        @media (max-width: 992px) {
          display: none;
        }

        & > .btn {
          margin-bottom: 0;
          background-color: $Surface-tinted;
          color: $Type-Link;
          border: none;
          border-radius: 0;
          padding: 4px 8px;

          &:hover {
            background: $Type-Link;
            color: $White;
          }

          &.zoom-in {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          }

          &.zoom-out {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
          }
        }

        .zoom-divider {
          margin: 0 10px;
          border-bottom: 1px solid $Divider;
        }
      }
    }
  }
}
