@import "../styles/variables.scss";

.events-page {
  position: relative;

  .network-error, .server-error {
    @media (max-width: 768px) {
      position: relative;
    }
  }

  .controls-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    align-items: center;
    background-color: #FAF9F8;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 8px;

    .polling-filters-container {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
    }

    .dropdown.sorting {
      margin-left: auto;

      @media (min-width: 1200px) {
        margin-left: 0;
      }

      .sort-text{
        margin-right: 16px;
        vertical-align: middle;
      }

      button {
        margin-bottom: 0;

        &:hover {
          border: 1px solid $Grey100;
        }

        &::after {
          display: none;
        }

        @media (max-width: 1199px) {
          padding: 0;
          height: 36px;
          width: 36px;
          border: none;

          &:hover {
            border: none;
          }
        }

        .fa-angle-down {
          padding-top: 5px;
          margin-left: 5px;
        }
      }

      .dropdown-menu {
        width: 305px;
        border: none;
        border-radius: 5px;
        box-shadow: 0px 1.937px 4.358px 0px rgba(0, 0, 0, 0.13), 0px 0.363px 1.089px 0px rgba(0, 0, 0, 0.10);

        .dropdown-item {
          padding: 15px;

          &.selected {
            font-weight: 700;
            color: $Info;
          }
        }
      }
    }

    .filters-component {
      position: relative;

      .open-filters {
        margin-left: 16px;

        @media (max-width: 1199px) {
          padding: 0;
          height: 36px;
          width: 36px;
          border: none;
        }
        
        .filters-btn__text {
          @media (max-width: 1199px) {
            display: none;
          }
        }

        svg {
          margin-left: 0;

          @media (min-width: 1200px) {
            margin-left: 8px;
          }
        }
      }

      .filters {
        @media (min-width: 768px) {
          max-height: initial;
          top: 2.4375rem;
          right: 0;
        }
      }
    }
  }
}

.empty-event-display {
  h2, h6 {
    color: $BC-Blue;
  }

  h6 {
    margin-top: 2rem;
  }
  strong {
    margin-top: 12px;
    display: inline-block;
  }
}

.event-header {
  display: flex;
  align-items: center;
  width: 100%;

  .eventType {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.eventType {
  display: flex;
  align-items: center;
  border-radius: 12px;
  border: none;
  padding: 8px 16px;
  font-weight: 700;
  font-size: 0.875rem;
  width: 100%;
  cursor: default;

  @media (min-width: 768px) {
    border-radius: 24px;
    padding: 4px 12px;
    width: auto;
  }

  .delay-icon-img {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 100%;
    }
  }
}

.closure .eventType {
  background: $Error;
  color: white;
}

.major .eventType {
  background: #FFE4E5;
  color: $Error;
}

.minor .eventType {
  background: #FEF0D8;
  color: $Gold110;
}

.chain-up .eventType {
  background: #FFEF76;
  color: #292929;
  border: 1px solid #292929
}

.updated-pill {
  background-color: $Blue80;
  color: $White;
  font-weight: 700;
  font-size: 0.875rem;
  border-radius: 24px;
  padding: 4px 12px;
  width: auto;
}

.update-count-pill {
  background-color: $Blue80;
  color: $White;
  font-weight: 700;
  font-size: 0.875rem;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  border: none;
  border-radius: 24px;
  padding: 4px 12px;
  z-index: 1000;
  white-space: nowrap;

  &:hover {
    background-color: $Blue70;
  }

  &.top {
    top: calc(58px + 9px);
  }

  &.bottom {
    bottom: 9px;
  }

  svg {
    margin-right: 4px;
  }
}
