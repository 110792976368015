@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "styles/variables";
@import "styles/bootstrap-customizations";
@import "styles/typography";

//Global styling
body {
  margin-top: 58px;
  touch-action: pan-x pan-y;
}

.divider {
  padding-bottom: 2em;
  margin-bottom: 1.5em;
  border-bottom: 1px solid $Divider;
}

//Wagtail CMS content
.cms-page {
  .container {
    max-width: 740px;
  }

  .page-header {
    .back-link {
      display: block;
      margin-bottom: 1rem;
    }

    .timestamp-container, .friendly-time-text{
      color: $Type-Link;
      font-size: 0.875rem;
      font-weight: 700;
    }

    .timestamp-container {
      display: flex;

      .friendly-time, .formatted-date {
        margin-left: 0.5ch;
      }
    }
  }

  .cms-body {
    .responsive-object {
      position: relative;
    }

    .responsive-object iframe,
    .responsive-object img,
    .responsive-object object,
    .responsive-object embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;
    }

    img {
      max-width: 100%;
      height: auto;
      margin: 12px 0;

      &.full-width {
        width: 100%;
        height: auto;
      }

      &.left {
        display: flex;
        margin-right: auto;
      }

      &.right {
        display: flex;
        margin-left: auto;
      }
    }

    padding-top: 0;

    p, ol, ul {
      margin-bottom: 0.25rem;
    }

    p:empty {
      height: 24px;
      margin-bottom: 0;
    }

    ol, ul {
      padding-right: 1.5rem;

      li {
        margin-bottom: 0.25rem;
      }
    }

    .callout {
      max-width: 740px;
      background-color: rgb(250, 249, 248);
      padding: 16px 24px;
      margin: 16px 0px;
      border-width: 1px 1px 1px 8px;
      border-top-style: initial;
      border-right-style: initial;
      border-bottom-style: initial;
      border-color: rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgba(0, 0, 0, 0.5) rgb(1, 51, 102);
      border-image: initial;
      border-left-style: solid;
      font-weight: 400;
      font-size: 16px;
      line-height: 27px;
    }
  }
}

// Common styling
.camera-orientations {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  background-color: $Blue10;
  border-radius: 4px;
  margin-top: 8px;
  padding: 4px 0px;

  .colocated-camera-icon {
    margin: 0 auto;
  }

  .btn.camera-direction-btn {
    margin: 0 auto;
    padding: 0px;
    border-radius: 8px;
    font-size: 0.875rem;
    font-weight: 700;
    width: 6ch;

    &:hover {
      background: $BtnHover-Primary;
      color: white;
    }

    &:not(.current) {
      background: none;
      border-color: transparent;
      color: $Type-Primary;
      font-weight: 400;

      &:hover {
        background: $BC-Blue-Alt;
        color: white;
      }
    }
  }
}

// Forms
.form-control {
  font-size: 1rem;
  padding: 0.385rem 0.75rem;
  line-height: 1;

  &::placeholder {
    color: $Type-Disabled;
  }
}

// Hide recaptcha badge
.grecaptcha-badge {
    visibility: hidden !important;
}

// Icon for links opening in new tabs
a[target="_blank"] {
  margin-right: 18px;

  &:not(.social-icon){
    position: relative;

    &:after {
      position: absolute;
      margin-top: 1px;
      margin-left: 4px;
      content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' alt='external link'%3E%3Cpath fill='%23474543' d='M336 0c-8.8 0-16 7.2-16 16s7.2 16 16 16H457.4L212.7 276.7c-6.2 6.2-6.2 16.4 0 22.6s16.4 6.2 22.6 0L480 54.6V176c0 8.8 7.2 16 16 16s16-7.2 16-16V16c0-8.8-7.2-16-16-16H336zM64 32C28.7 32 0 60.7 0 96V448c0 35.3 28.7 64 64 64H416c35.3 0 64-28.7 64-64V304c0-8.8-7.2-16-16-16s-16 7.2-16 16V448c0 17.7-14.3 32-32 32H64c-17.7 0-32-14.3-32-32V96c0-17.7 14.3-32 32-32H208c8.8 0 16-7.2 16-16s-7.2-16-16-16H64z'/%3E%3C/svg%3E");
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      color: $Type-Secondary;
      width: 0.625rem;
    }
  }
}

// Advisories button
.btn.advisories-btn {
  background: #FEF0D8;
  width: fit-content;
  font-size: 0.875rem;
  font-weight: 700;
  color: $Gold110;
  border-radius: 5px;
  border: 1px solid $BC-Yellow;
  margin-bottom: 0;
  display: flex;
  align-items: center;

  &:hover, &:active, &.active, &.open {
    color: $Gold110;
    background: darken(#FEF0D8, 10%);
  }

  &:focus, &.focus {
    outline: $BC-Yellow;
  }

  svg {
    margin-right: 8px;
  }

  .advisories-count {
    margin-left: 12px;
    font-size: 0.625rem;
    color: white;
    background: $Error;
    width: 1rem;
    height: 1rem;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


// Favourite star button
.favourite-btn, .viewMap-btn {
  border: none;
  background: $Grey10;
  font-weight: 700;
  font-size: 0.875em;
  color: $Type-Link;
  border-radius: 4px;
  padding: 0.375rem 0.5rem;

  svg {
    margin-right: 8px;
    color: $Type-Link;
  }
  &:hover {
    background: $BtnHover-Secondary;
  }

  &.favourited {
    &:hover {
      svg {
        color: $Warning;
        fill: $Warning;
      }
    }
    svg {
      color: $Warning;
      fill: $Warning;
    }
  }
}

.btn-tertiary {
  border: none;
  background: none;
  font-weight: 700;
  font-size: 0.875rem;
  padding: 0.375rem 0.5rem;
  &:hover {
    background: $BtnHover-Tertiary;
    transition: 0.2s ease-in-out all;
  }
}

.link-in-text {
  color: $Type-Primary;
}

.space-between-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
}

.App {
  &:has(.auth-modal), &:has(.overlay.open) {
    div[class*='-page'] {
      position: fixed;
      width: 100%;
    }
  }
  div[class*='-page'] {
    .container {
      @media (min-width: 576px) {
        max-width: unset;
        padding: 32px 16px;
      }

      @media (min-width: 992px) {
        padding: 44px 64px;
        max-width: 1828px;
      }
    }

    &.cms-page {
      .container {
        max-width: 980px;
      }
    }
  }
}

// advisories and search overlay
.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  min-height: 0;
  z-index: 6;
  background: white;
  overflow: hidden;
  transition: all 0.2s ease-in-out;

  &.open {
    min-height: 100%;
  }

  @media (min-width: 768px) {
    width: 0;
    min-height: 100%;
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);

    &.open {
      width: 400px;
    }
  }

  .overlay__header {
    font-size: 1.125rem;
    color: $BC-Blue;
  }

  &.advisories-overlay {
    &.open {
      padding: 3.625rem 0 1rem;
    }
  }

  &.search-overlay {

    &.open {
      padding: 5rem 1rem 1rem;
    }

    .routing {
      padding: 0 15px 0 0;

      .routing-container .typeahead-container {
        width: 100%;
      }
    }
  }

  &.filters-overlay {
    &.open {
      .overlay__header {
        padding: 5rem 1rem 0;
      }
    }
  }

  .close-overlay {
    position: absolute;
    top: 4.75rem;
    right: 1rem;
    padding: 0;
    min-width: 0;
    line-height: 1.25rem;
    height: 2rem;
    width: 2rem;
    border-radius: 50%;
    background: none;
    border: none;
    font-size: 1rem;
    color: $BC-Blue;
  }
}

:focus, :focus-visible {
  outline: 2px solid $Focus;
  box-shadow: 0 0 0 0.25rem rgba(1, 51, 102, 0.25);
}

button.text-only-btn {
  margin: 4px;

  &:hover {
    text-decoration: underline;
  }
}

.back-link {
  font-weight: 700;
  text-decoration: none;
  font-size: 0.875rem;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
    svg {
      transform: translateX(-2px);
      transition: 0.4s transform $Anim-gentle;
    }
  }

  svg {
    margin-right: 8px;
    transition: 0.4s transform $Anim-gentle;
  }
}

.link-div {
  cursor: pointer;
}
