@import "../styles/variables.scss";

.saved-cameras-page {
  position: relative;

  .container {
    @media (min-width: 576px) {
      max-width: 1828px;
      padding: 32px 48px;
    }

    @media (min-width: 992px) {
      padding: 44px 64px;
    }
  }

  .network-error, .server-error {
    @media (max-width: 767px) {
      position: relative;
    }
  }

  .camera-list {
    .highway-group {
      &:nth-of-type(even) {
        background-color: white;
      }
    }
  }
}
