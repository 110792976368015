@import "./styles/variables.scss";

.page-header {
  background-color: $BC-Blue-Light;

  .page-title, .page-description {
    color: $BC-Blue;
  }

  .page-title {
    font-size: 2.25rem;
    margin-bottom: 1rem;
  }

  .page-description {
    margin-bottom: 1rem;
  }
}