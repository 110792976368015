.route-map {
  .map-elem {
    opacity: 0;
    width: 400px;
    height: 200px;
    position: absolute;
    z-index: -1;
  }

  .maps {
    .thumbnail img {
      border: solid 1px black;
    }
  }
}
