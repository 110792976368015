@import "../../styles/variables.scss";

table {
  font-size: 0.875rem;
  width: 100%;

  thead {
    background-color: $BC-Blue-Light;
    tr {
      th {
        font-weight: 400;
        color: $BC-Blue;

        .cursor-pointer {
          white-space: nowrap;

          svg {
            margin-left: 8px;
          }
        }
      }
    }
  }

  tbody {
    tr {
      &:focus {
        outline: 0;
        box-shadow: none;
      }
      &.major, &.closure {

        .typeDisplay {
          color: $Error;
          font-size: 0.875rem;
          font-weight: 700;
        }

        td {
          .roadName {
            color: $Error;
          }
        }
      }

      td {
        color: $Type-Primary;

        &.event-type {
          text-align: center;
        }

        &.severity, &.direction {
          text-transform: capitalize;
        }
      }
    }

    .headerRow {
      &.highlighted {
        background-color: $Blue20;

        & + .dataRow, & + .dataRow:hover {
          background-color: $Blue20;

          & + .mapLinkRow, & + .mapLinkRow:hover {
            background-color: $Blue20;
          }
        }

        &:hover {
          background-color: $Blue20;
        }
      }

      td {
        padding-top: 12px;
        padding-bottom: 16px;
        vertical-align: top;

        .roadName {
          color: $Info;
          font-size: 1.125rem;
          font-weight: bold;
        }

        .directionDisplay {
          font-size: 0.875rem;
          font-weight: 700;
          color: $Grey80;
        }

        p {
          margin-bottom: 0;
        }
      }
    }

    .dataRow {
      td {
        padding-top: 0;
        padding-bottom: 0;
        vertical-align: top;

        &.location_description span {
          display: inline-block;
          padding-bottom: 16px;
        }
      }
    }

    .mapLinkRow {
      border-bottom: 1px solid $Divider;

      td {
        padding-top: 0;
        padding-bottom: 16px;
        vertical-align: top;
      }
    }
  }

  td {
    padding: 0 16px;
  }

  th {
    padding: 1rem 0 1rem 1rem;
    vertical-align: middle;
  }
}

.viewOnMap-btn.btn.btn-primary {
  font-weight: 700;
  color: $Type-Link;
  border: none;
  border-radius: 4px;
  background: $Grey10;
  padding: 8px 16px;
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  &:hover {
    background-color: $BtnHover-Secondary;
  }
  svg {
    margin-right: 8px;
  }

  td {
    padding: 0 16px;
  }
  th {
    padding: 1rem 0 1rem 1rem;
    vertical-align: middle;
  }
}

.location_description {
 max-width: 167px;
}
.closest_landmark {
  max-width: 155px;
}
.optimized_description {
  max-width: 274px;
}
.last_updated {
  max-width: 128px;
}
.next_update {
  max-width: 128px;
}


td.last_updated .friendly-time-text,
td.next_update .friendly-time-text {
  font-size: 0.875rem;
}

.loading {
  &.display_category {
    width: 5.6192%
  }

  &.location_description {
    width: 9.5729%
  }

  &.closest_landmark {
    width: 18.0560%
  }

  &.optimized_description {
    width: 17.7962%
  }

  &.last_updated {
    width: 8.7687%
  }

  &.next_update {
    width: 9.8318%
  }
}

.event-table-header {
  span {
    display: inline-block;
    width: 100%;
  }
  &:last-child {
    span {
      border: 0;
    }
  }
}
