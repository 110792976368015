@import "../styles/variables.scss";

.hidden-desktop {
  @media (min-width: 992px) {
    display: none;
  }
}

.saved-routes-page {
  position: relative;

  .not-verified, .verified {
    background-color: $MyAccountWarning;
    color: $MyAccountWarningText;
    padding: 0.5rem 4rem;
    font-size: 0.875rem;

    @media (max-width: 992px) {
      padding: 1rem 0.75rem;
    }

    .verify-link {
      margin-bottom: 0;

      @media (min-width: 992px) {
        margin-left: 1rem;
      }

      @media (max-width: 992px) {
        margin-top: 1rem;
      }
    }
  }

  .verified {
    background-color: $MyAccountSuccess;
    color: $MyAccountSuccessText;
  }

  .container {
    @media (min-width: 576px) {
      max-width: 1828px;
      padding: 32px 48px;
    }

    @media (min-width: 992px) {
      padding: 44px 64px;
    }
  }

  .content-container {
    display: grid;
    grid-template-rows: 1fr auto;
    transition: all 0.3s ease;

    &:has(.route-list.collapsed) {
      grid-template-rows: 0fr auto;
    }

    .route-list {
      display: grid;
      column-gap: 1.5em;
      row-gap: 36px;
      grid-template-columns: repeat(1, 1fr );
      grid-template-rows: auto;
      opacity: 1;
      overflow: auto;
      transition: all 0.3s ease;

      @media (min-width: 576px) {
        grid-template-columns: repeat(2, 1fr );
      }

      @media (min-width: 1240px) {
        grid-template-columns: repeat(3, 1fr );
      }

      @media (min-width: 1480px) {
        grid-template-columns: repeat(4, 1fr );
      }

      &.collapsed {
        opacity: 0;
        overflow: hidden;
      }

      .route-card {
        border-radius: 4px;
        border: none;
        outline: 2px solid transparent;
        transition: all 0.3s ease;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 576px) {
          width: unset;
          margin: unset;
        }

        .route-details {
          display: flex;
          flex-direction: column;
          height: 100%;

          .route-title {
            .route-tools {
              padding: 0.5rem;
              background-color: $Blue10;
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;

              .notifications-toggle {
                .form-switch {
                  .form-check-input {
                    margin-left: 0;
                    margin-right: 8px;
                  }
                  .form-check-label{
                    font-size: 0.875rem;
                    font-weight: 700;
                    color: $GreyOnBlue;
                  }
                }
              }
            }

            .card-img-box {
              border-top-left-radius: 0;
              border-top-right-radius: 0;
            }
          }

          .route-alt-name {
            margin-bottom: 0;
          }

          .myRoute-actions {
            display: flex;
            justify-content: space-between;
            flex-grow: 1;
            align-items: flex-end;
            margin-top: 16px;
          }

          .viewCams-btn, .favourite-btn {
            font-size: 0.875rem;
            font-weight: 700;
            color: $Type-Link;
            border: none;
            background: none;
            padding: 0;

            svg {
              margin-right: 8px;
            }
          }

          .viewCams-btn {
            &.disabled {
              color: $Type-Disabled;
            }
          }
        }
      }
    }

    .fav-cams-on-route {
      background: white;
      transition: all 0.3s ease;
      z-index: 1;
      overflow: hidden;

      &.expanded {
        overflow: auto;
      }

      .header-row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1rem;

        .close-btn {
          cursor: pointer;
          font-size: 20px;
          margin-top: 4px;
        }

        .caption {
          font-size: 1.125rem;
          color: $Grey80;
          margin-bottom: 0rem;
        }
      }
    }
  }
}
