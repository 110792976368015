@import "../styles/variables.scss";

.account-page {
  .verified {
    background-color: $MyAccountSuccess;
    color: $MyAccountSuccessText;
    padding: 1rem 4rem;
    font-size: 0.875rem;

    @media (max-width: 992px) {
      padding: 1rem 0.75rem;
    }

    .verify-link {
      text-decoration: underline;
      cursor: pointer;

      @media (min-width: 992px) {
        display: inline;
        margin-left: 1rem;
      }

      @media (max-width: 992px) {
        margin-top: 1rem;
      }
    }
  }

  .email-address {
    .email, .header {
      font-size: 1.1rem;
      font-weight: 700;
      color: $MyAccountHeader;
    }

    .email {
      font-weight: 400;
    }

    .not-verified {
      background-color: $MyAccountWarning;
      color: $MyAccountWarningText;
      padding: 0.5rem 1rem;
      font-size: 0.875rem;

      .btn {
        margin-top: 1rem;
      }
    }
  }

  #deactivate-btn {
    margin-top: 4rem;
  }
}

#deactivate-modal {
  .modal-header {
    background-color: white;
    border: none;
    padding: 1.5rem;

    .modal-title {
      color: $BC-Blue;
      font-weight: 700;
    }

    .btn-close {
      filter: none;
    }
  }

  .modal-body {
    padding-top: 0;
  }

  .modal-buttons {
    margin-top: 3rem;
  }
}
