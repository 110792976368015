@import "./styles/variables.scss";

.footer-container {
  margin-top: 3rem;
  background: $Grey10;

  .landAcknowledgement {
    background-color: rgb(41, 41, 41);
    line-height: 21px;
    border-top: 4px solid rgb(252, 186, 25);
    border-bottom: 4px solid rgb(252, 186, 25);

    p {
      font-size: 0.875rem;
      color: rgb(250, 249, 248);
      max-width: unset;
      margin-bottom: 0;
    }
  }

  .footer {
    max-width: 1124px;
    margin: auto;
    font-size: 0.875rem;
    color: rgb(70, 67, 65);
    line-height: 21px;
    padding: 16px;

    p {
      font-size: 0.875rem;
    }

    a{
      color: rgb(70, 67, 65);
    }

    @media (min-width: 576px) {
      padding: 32px;
    }

    @media (min-width: 992px) {
      padding-top: 56px;
    }

    .flex-container {
      display: flex;
      flex-direction: column;
      gap: 0px;

      @media (min-width: 576px) {
        flex-direction: row;
        gap: 32px;
        -webkit-box-pack: justify;
        justify-content: space-between;
      }

      .contact {
        flex: 0 0 50%;

        .footer-logo {
          height: 56px;
          margin: 1rem 0;
        }
        .footer-text {
          margin-top: 1rem;
        }
      }

      .more-info {
        flex: 0 0 auto;
        h2 {
          text-transform: uppercase;
          font-size: 0.875rem;
          line-height: 24px;
          margin: 16px 0px;
        }

        .link-list {
          list-style-type: none;
          padding: 0;
          columns: 1;
          margin: 0;
          gap: 48px;

          li {
            width: unset;
            margin-bottom: 16px;

            @media (min-width: 992px) {
              width: 160px;
            }

            a {
              text-decoration: none;
              color: $Type-Secondary;

              &:hover {
                text-decoration: underline;
              }

              svg {
                margin-left: 4px;
                font-size: 0.785em;
              }
            }
          }
        }
      }
    }

    .bottom{
      display: flex;
      flex-direction: column;
      -webkit-box-pack: justify;
      justify-content: space-between;
      border-top: 1px solid rgb(1, 51, 102);
      padding-top: 32px;
      margin-top: 32px;

      @media (min-width: 992px) {
        flex-direction: row;
      }

      .connect {
        display: flex;
        gap: 10px;
        margin-bottom: 16px;

        @media (min-width: 992px) {
          margin-bottom: 0;
        }

        .footer-link {
          background: rgb(250, 249, 248);
          border: 1px solid rgb(59, 58, 57);
          border-radius: 25px;
          width: 48px;
          height: 48px;
          display: flex;
          -webkit-box-align: center;
          align-items: center;
          -webkit-box-pack: center;
          justify-content: center;
        }

        .footer-link:hover {
          background: #DCDCDC;
        }
      }
    }
  }
}
