@import "../styles/variables.scss";

.report-problem-page {

  .section {

    &:not(:last-of-type) {
      margin-bottom: 80px;
    }

    h2 {
      color: $BC-Blue;
      margin-bottom: 20px;
    }

    &__content {
      display: flex;
      gap: 24px;
      flex-wrap: wrap;

      @media (min-width: 992px) {
        flex-wrap: nowrap;
      }
    }

    .content-card {
      text-decoration: none;
      flex-basis: 100%;
      padding: 24px;
      background-color: $Grey10;
      border-radius: 4px;
      border: 1px solid $Divider;
      outline: 2px solid transparent;
      transition: all 0.3s ease;
      width: 100%;
      margin: 0 auto;

      &.disabled {
        color: $Type-Disabled;

        p {
          color: $Type-Disabled;
        }

        .attention {
          color:$Type-Primary;
        }

        .circle-icon {
          background: $Type-Disabled;
        }
      }

      &:not(.disabled):hover {
        box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13);
        background-color: $White;
      }

      h4 {
        color: $Type-Link;
      }
    }


    .circle-icon {
      background: $BC-Blue;
      margin-bottom: 24px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $White;
    }

    &--online {
      .circle-icon {
        width: 96px;
        height: 96px;
        font-size: 40px;
      }
    }

    &--phone {
      .section__content {
        .content-card {
          &:hover {
            .tel-number {
              text-decoration: underline;
            }
          }
          
          .circle-icon {
            width: 64px;
            height: 64px;
            font-size: 32px;
          }

          .tel-number {
            text-decoration: none;
            color: $Type-Primary;
          }
        }
      }
    }
  }
}