@import "../styles/variables.scss";

.verify-email-page {
  p {
    font-size: 1rem;
    margin-bottom: 1.5rem;
  }

  .instructions {
    font-size: 1.1rem;
  }
}
