@import "../../styles/variables.scss";

.share-url {
  .copy-btn {
    padding: .4rem .75rem;
    border-radius: 4px;
    background: #EDEBE9;
    border: none;
    color: $Type-Primary;
    transition: 0.3s;
    font-size: 0.875rem;

    &:hover {
      background: #F2F2F2;
    }

    svg {
      margin-right: 0.4rem;
    }
  }
}

.popup {
  &--camera {
    .copy-btn {
      background: darken($Surface-camera, 8%);

      &:hover {
        background: darken($Surface-camera, 15%);
      }
    }
  }
  &--event {
    &.major, &.closures {
      .copy-btn {
        background: darken($Surface-major, 8%);

        &:hover {
          background: darken($Surface-major, 15%);
        }
      }

    }
    &.minor {
      .copy-btn {
        background: darken($Surface-minor, 8%);

        &:hover {
          background: darken($Surface-minor, 15%);
        }
      }

    }
    &.chainUps {
      .copy-btn {
        background: darken($Gold40, 8%);

        &:hover {
          background: darken($Gold40, 15%);
        }
      }

    }
  }
  &--ferry {
    .copy-btn {
      background: darken($Surface-ferry, 8%);

      &:hover {
        background: darken($Surface-ferry, 15%);
      }
    }

  }
  &--weather {
    .copy-btn {
      background: darken($Surface-weather, 8%);

      &:hover {
        background: darken($Surface-weather, 15%);
      }
    }

  }
  &--reststop {
    .copy-btn {
      background: darken($Surface-reststop, 8%);

      &:hover {
        background: darken($Surface-reststop, 15%);
      }
    }

  }
}
