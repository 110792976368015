@import "../../../styles/variables";

.popup {
  // Advisories panel
  &--advisories {
    .popup__title {
      background-color: $Gold30;
      border-top: 4px solid $BC-Yellow;

      &__icon {
        background-color: $BC-Yellow;
        color: $Gold110;
      }

      .name {
        color: $Gold110;
      }
    }

    .popup__content {
      padding: 12px 16px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .advisories-list .advisory-li .advisory-li__content .advisory-li-title-container .advisory-li-title {
        font-size: 1rem;
      }
      .advisory-li {
        .timestamp-container .friendly-time {
          margin-left: 0;
        }
        &:hover {
          background-color: $Grey10;
          transition: 0.1s ease-in-out background-color;    
        }
      }

      .advisory-li-teaser {
        max-width: 95%;
      }
    }
  }
}
