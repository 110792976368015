@import "../../styles/variables.scss";

ul.bulletins-list {
  li.bulletin-li {
    cursor: pointer;
    width: fit-content;
    &:hover {
      .bulletin-li-title {
        text-decoration: underline;
      }
    }
  }
}
.empty-bulletin-display {
  h2 {
    margin-bottom: 2rem;
    color: $BC-Blue;
  }
}