@import "../styles/variables.scss";

.camera-page {
  position: relative;
  overflow-x: hidden;

  .network-error, .server-error {
    @media (max-width: 768px) {
      position: relative;
    }
  }

  #back-container {
    padding-top: 16px;
    padding-bottom: 16px;
  }
  .camera-details {
    display: flex;
    flex-direction: column;

    @media (min-width: 1378px) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__description {
      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 1rem;
        width: 90%;
        @media (min-width: 1440px) {
          width: initial;
        }
        h2 {
          color: $BC-Blue;
          margin-bottom: 0;
        }
        .favourite-btn {
          height: 32px;
          margin-top: 0.5rem;
          svg {
            margin-right: 8px;
          }
        }
      }

      .body--large {
        margin-bottom: 1em;

        @media (min-width: 992px) {
          margin-top: 8px;
          margin-bottom: 0;
        }
      }
    }

    &__more {
      display: flex;
      justify-content: space-between;
      margin: 0;

      @media (min-width: 576px) {
        justify-content: flex-start;
        gap: 60px;
        margin: 1.5rem 0 0;
      }

      @media (min-width: 1240px) {
        justify-content: flex-start;
        gap: 60px;
        margin: 0;
      }


      &__update, &__elevation, &__hwy {
        display: flex;
        flex-direction: column;
        align-items: left;
        justify-content: flex-end;

        &-detail {
          display: flex;
          align-items: center;
          margin-bottom: 1rem;
          p {
            margin-bottom: 0;
          }
        }

        .tooltip-info {
          margin-left: 6px;
          color: #FFFFFF;
          background-color: $Grey80;
          padding: 0 5px;
          border-radius: 0.5rem;
          border: none;
          font-size: 0.625rem;
          height: 14px;

          &:hover {
            cursor: pointer;
          }
        }

        .label {
          margin-top: 0.2857em;
          margin-bottom: 0;
          white-space: nowrap;
          font-weight: 400;
          color: $Grey80;

          display: flex;
          align-items: center
        }

        .number {
          font-size: 1rem;
          font-weight: 700;
        }
      }

      &__update, &__elevation {

        svg {
          width: 1rem;
          color: $Grey80;
        }
      }
    }
  }

  .actions-bar {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: $BC-Blue-Light;
    color: $BC-Blue;
    border-radius: 8px;

    @media (min-width: 992px) {
      min-height: 48px;
    }

    &--nearby {
      height: 48px;
    }

    &--webcam {
      .camera-orientations {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 0;
        padding: 0;
        background-color: inherit;

        @media (min-width: 576px) {
          flex-direction: row;
          align-items: center;
          margin-top: 0;
        }
        .camera-orientations-group {
          margin-top: 4px;
          @media (min-width: 576px) {
            margin-top: 0;
          }
        }
        .btn.camera-direction-btn {
          width: auto;
          padding: 0 12px;
          @media (min-width: 992px) {
            padding: 0 8px;
          }
          @media (min-width: 1200px) {
            padding: 0 12px;
          }
          & + .btn.camera-direction-btn {
            margin-left: 4px;
          }
        }


        .camera-direction-label {
          margin-right: 0.5rem;
          font-size: 0.875rem;
          @media (min-width: 1240px) {
            font-size: 1rem;
            padding-left: 0;
          }
          span {
            margin-left: 8px;
          }
        }

        .camera-directions-group {
          margin-left: 8px;

          @media (min-width: 576px) {
            margin-left: 0;
          }
          .camera-direction-btn {
            width: 40px;
            max-width: initial;
          }
        }
      }
    }
  }

  .camera-notifications {
    background-color: $Blue80;
    color: white;
    display: flex;
    align-items: center;
    padding: 12px 24px;
    border-radius: 8px;
    margin-top: 16px;

    @media (min-width: 992px) {
      width: calc(50% - 12px);
    }

    svg {
      margin-right: 8px;
    }

    p {
      font-weight: 700;
      color: white;
      margin-bottom: 0;
    }
  }

  .camera-imagery {
    margin: 0;
    @media (min-width: 768px) {
      padding: 0;
    }
    .tab-content {
      padding: 0 12px;
      @media (min-width: 576px) {
        padding: 0;
      }
      & > .tab-pane {

        .image-wrap {
          position: relative;
          margin-top: 8px;

          &.unavailable {

            .card-img-box {
              background-color: $Surface-major;
              padding: 12px;
              display: flex; /* Add this line */
              align-items: center; /* Add this line */
              justify-content: center; /* Optional: center horizontally as well */
              height: fit-content;
              min-height: 388px;
            }

            .unavailable-message {
              padding: 12px;

              svg, p, ul {
                color: $Error;
              }
              p:has(+ul) {
                margin-bottom: 0;
              }

              svg {
                font-size: 1.25rem!important;

                &.icon {
                  padding: 0!important;
                }
              }

              p {
                &.bold {
                  font-weight: 700!important;
                  font-size: 1rem;
                  line-height: 1.5;
                  margin-top: 0.25rem;
                  margin-bottom: 0.75rem;
                }
              }
            }
          }

          &.updated {
            .card-banner, .card-pill {
              background-color: $Blue80;

              p, svg {
                color: $White;
              }
            }
          }

          &.stale {
            .card-banner, .card-pill {
              background-color: $Grey50;

              p, svg {
                color: $Grey100;
              }
            }

            .card-pill {
              &:hover {
                background-color: $Grey70;
              }
            }
          }

          &.delayed {
            .card-banner, .card-pill {
              background-color: $Gold20;

              p, svg {
                color: $BC-Yellow-Dark;
              }
            }

            .card-pill {
              &:hover {
                background-color: $Gold60;
              }
            }

            .card-banner {
              svg {
                font-size: 1.5rem!important;

                &.icon {
                  padding: 0!important;
                }
              }

              p {
                font-weight: 400!important;

                &.bold {
                  font-weight: 700!important;
                  font-size: 1.25rem;
                  line-height: 1.875rem;
                  margin-top: 0.25rem;
                  margin-bottom: 0.75rem;
                }
              }

              ul {
                color: $BC-Yellow-Dark;
              }
            }
          }

          &.unavailable {
            .card-pill {
              &:hover {
                background-color: $Error;
              }
            }
          }

          .card-img-box {
            height: 388px;
            position: relative;
            border-radius: 8px;

            img {
              width: 100%;
              height: 388px;
              object-fit: cover;

              &.image-gallery-image {
                height: 388px;
                cursor: auto;
                object-fit: fill;
              }
            }

            .card-banner, .card-pill {
              position: absolute;
              display: flex;
              align-items: center;

              &.hidden {
                transform:translateY(-200%);
                transition: transform 0.7s;
              }

              &.bounce {
                animation: bounce 0.7s ease;
                animation-fill-mode:forwards;
              }

              @keyframes bounce {
                  0% { transform:translateY(-200%); }
                  40% { transform:translateY(20%); }
                  100% { transform:translateY(0%); }
              }

              p {
                font-weight: 700;
                margin-bottom: 0;
              }
            }

            .card-banner {
              padding: 96px 18px 12px 18px;
              top: -84px;
              width: 100%;
              justify-content: space-between;
              align-items: normal;

              p, svg {
                font-size: 1rem;
              }

              p {
                flex: 1;
              }

              svg {
                padding: 5px 8px;
                border-radius: 16px;

                &:not(.icon):hover {
                  background-color: rgba(0,0, 0,0.1);
                }
              }
            }

            .card-pill {
              top: 8px;
              right: 8px;
              padding: 6px 12px;
              border-radius: 16px;
              transform:translateY(-200%);
              transition: background-color 0.1s ease-in-out;

              &:hover {
                transition: background-color 0.1s ease-in-out;
              }

              p, svg {
                font-size: 0.875rem;
              }

              svg {
                margin-left: 4px;
              }
            }

            .image-gallery {
              box-shadow: 0px 1.600000023841858px 3.5999999046325684px 0px rgba(0, 0, 0, 0.13), 0px 0.30000001192092896px 0.8999999761581421px 0px rgba(0, 0, 0, 0.10);
              margin-bottom: 1rem;

              &-slide-wrapper {
                display: flex;
                flex-wrap: wrap;

                .replay-control {
                  display: flex;
                  justify-content: center;
                  margin: 0 0 12px 0;

                  @media (min-width: 992px) {
                    margin: auto 0;
                  }

                  .replay-btn {
                    color: $Type-Primary;
                    background-color: $White;
                    border: none;
                    width: 60px;
                    height: 60px;
                    border-radius: 30px;
                    padding: initial;
                    margin-bottom: 0;

                    @media(min-width: 992px) {
                      width: 36px;
                      height: 36px;
                      border-radius: 18px;
                    }

                    &:hover {
                      background-color: $Grey30;
                    }

                    &:focus {
                      border: rgb(46, 93, 215) solid 2px;
                    }
                  }
                }

                //Replay the day on mobile
                .range-slider-container {
                  order: 2;
                  margin: 0 15px;
                  width: calc(100% - 30px);
                }

                .replay-control--backward {
                  order: 3;
                  width: 33.33%;
                  svg {
                    font-size: 1.25rem;
                  }
                }

                .replay-control--forward {
                  order: 5;
                  width: 33.33%;
                  svg {
                    font-size: 1.25rem;
                  }
                }

                .image-gallery-swipe {
                  order: 1;
                  width: 100%;
                }

                .replay-control--play {
                  order: 4;
                  width: 33.33%;
                  svg {
                    font-size: 1.5rem;
                  }
                }

                //Replay the day on desktop
                @media (min-width: 992px) {
                  .range-slider-container {
                    order: 5;
                    margin: 5px 15px;
                    width: calc(70% - 30px);
                  }

                  .replay-control--backward {
                    order: 2;
                    width: 10%;
                  }

                  .replay-control--forward {
                    order: 4;
                    width: 10%;
                  }

                  .image-gallery-swipe {
                    order: 1;
                    width: 100%;
                  }

                  .replay-control--play {
                    order: 3;
                    width: 10%;
                  }
                }
              }
            }
          }

          .timestamp {
            background-color: $Black;
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            bottom: 0;
            width: 100%;
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;

            svg {
              @media (max-width: 767px) {
                width: 48px;
              }
            }
            p, .friendly-time-text {
              color: $White;
              font-size: 0.875rem;
              margin-bottom: 0;
              @media (max-width: 767px) {
                font-size: 0.75rem;
              }
            }

            .driveBC {
              margin-top: 4px;
              margin-right: 10px;
            }
          }
        }
      }
    }

    #camera-details-tab-webcam {
      display: flex;
    }

    .replay-div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      position: relative;

      @media (min-width: 576px) {
        justify-content: space-between;
      }
      @media (min-width: 992px) {
        align-items: center;
      }

      svg {
        margin-right: 8px;
        margin-top: 4px;
      }

      .replay-the-day {
        margin-bottom: 0.5em;

        @media (min-width: 576px) {
          margin-bottom: 0;
        }
      }

      .replay-the-day {
        .form-switch {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 0;

          @media (min-width: 576px) {
            flex-direction: row;
          }

          .form-check-input {
            @media (min-width: 576px) {
              margin-left: 8px;
              position: static;
            }
          }
        }
      }
    }

    //Desktop view without tabs
    @media (min-width: 992px) {
      max-width: unset;
      margin: 0.875rem 0 0;

      .nav-tabs {
        display: none;
        margin: 0 16px;
      }

      .tab-content {
        display: flex;

        & > .tab-pane {
          opacity: 1;
          display: block;
          width: calc(50% - 10px);

          &:first-of-type {
            margin-right: 12px;
          }

          &:last-of-type {
            margin-left: 12px;
          }
        }
      }
    }

    .credit {
      font-size: 0.875rem;
      line-height: 1.5;

      &.under-replay {
        margin-top: 112px;

        @media (min-width: 992px) {
          margin-top: 50px;
        }
      }
    }
  }

  //Custom container for full width on mobile, and padding for bigger width
  .container--full-width {
    max-width: 1124px;
    margin: auto;
    padding: 32px 0 0 0;

    @media (min-width: 576px) {
      padding: 32px;
    }

    @media (min-width: 1200px) {
      padding: 44px 0;
    }

    & > div:not(.camera-notifications, .camera-imagery),
    .camera-imagery .nav-tabs,
    .replay-div {
      margin-left: 16px;
      margin-right: 16px;

      @media (min-width: 576px) {
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

      }
      @media (min-width: 1240px) {
        flex-direction: row;
      }
    }
  }

  //Map in Context
  .map-context-wrap {
    position: relative;
    top: 0rem;
    bottom: 0;
    width: 100%;
    height: 388px;
    margin-top: 8px;

    .map {
      width: 100%;
      height: 100%;
      border-radius: 8px;

      .ol-scale-line {
        bottom: 8px;
        right: 8px;
      }
    }

    .map-btn {
      &:not(.cam-location) {
        background-color: $White;

        &:hover, &.open {
          background: $Type-Link;
        }
      }

      &.map-view {
        position: absolute;
        top: -49px;
        left: 8px;
        bottom: initial;
        right: initial;
      }

      &.open-filters {
        position: absolute;
        top: -49px;
        right: 8px;
        bottom: initial;
        left: initial;
      }

      &.cam-location {
        position: absolute;
        bottom: 8px;
        left: 8px;
        display: flex;
        align-items: center;
      }

      &.zoom-btn {
        bottom: 48px;
        right: 8px;
      }
    }

    .filters {
      position: absolute;
      right: 0;
      bottom: 0;
      left: initial;
      max-height: initial;
      height: auto;

      @media (min-width: 992px) {
        top: 0;
        bottom: initial;
        right: 8px;
      }
    }
  }
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.custom-spinner {
  width: 40px;
  height: 40px;
}

@media (max-width: 767px) {
  .custom-spinner {
    width: 30px;
    height: 30px;
  }
}
