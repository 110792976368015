@import "../../../../styles/variables";

.forecast-container {
  padding: 1rem 0 0;
}

.forecast-tab-list {
  .nav-tabs {
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 1px solid #D9D9D9;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    padding: 0;

    li, button, div {
      display: flex;
      flex: 1;
    }
    li {
      flex: 0 auto;
    }

    .nav-item {
      padding-right: 1rem;
      &:last-child {
        padding: 0;
      }

      button {
        padding: 0;
        &:hover {
          background: none;
          color: $Grey80;
        }

        .tab-title {
          flex-direction: column;
          align-items: flex-start;
          padding: 4px 0;

          .date-display {
            font-size: 0.875rem;
          }

          .active {
            font-weight: 700;
          }

          .temp-display {
            justify-content: space-between;
            width: 100%;

            div {
              flex: unset;
            }
          }
        }
      }
    .nav-link {
      border-bottom: 1px solid #d9d9d9;
      transition: 0.2s border ease-in-out;
      &.active {
        border-bottom: 2px solid $BC-Blue;
        transition: 0.2s border ease-in-out;
      }
      &:hover {
        border-bottom: 2px solid #605E5C;
        transition: 0.2s border ease-in-out;
      }
    }
    }
  }
}
