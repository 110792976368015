@import "../../styles/variables.scss";

.event-card {
  margin-bottom: 40px;
  box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.10), 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);  border-radius: 4px;

  &__title {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .event-header {
      padding: 8px 12px;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;

      .eventType {
        padding: 0;
        border: none;
      }
    }

    .route {
      font-weight: 700;
    }
  }

  &__details {

    & > div {
      display: flex;

      .header {
        color: $BC-Blue;
        width: fit-content;
        font-size: 0.875rem;
        flex: 0 0 116px;
        display: flex;
        padding: 8px 8px 8px 12px;
      }

      .content {
        color: $Type-Primary;
        font-size: 0.875rem;
        padding: 8px;
        flex-grow: 1;
        display: flex;
        align-items: baseline;
        flex-direction: column;
      }
    }

    .name {
      .content {
        display: block;
        padding: 8px 12px;

        .route {
          font-size: 1.25rem;
          font-weight: 700;
          color: $BC-Blue;
        }
        .direction {
          font-size: 0.875rem;
          font-weight: 700;
          color: $Type-Secondary;
        }
      }
    }

    .map {
      .content {
        border-bottom: none;
        display: flex;
        justify-content: center;

        .viewMap-btn {
          font-size: 1rem;
        }
      }
    }

    .viewOnMap-btn {
      width: 100%;
      padding: 12px;
    }
    .friendly-time-text {
      font-size: 0.875rem;
    }
  }

  &.major, &.closure {
    .name {
      .route {
        color: $Error;
      }
    }
  }

  &.highlighted {
    .event-card__details {
      background-color: $Blue20;
    }
  }

  &.closure .event-header {
    background: $Error;
    color: white;
  }

  &.major .event-header {
    background: #FFE4E5;
    color: $Error;
  }

  &.minor .event-header {
    background: #FEF0D8;
    color: $Gold110;
  }

  &.chain-up .event-header {
    background: #FFEF76;
    color: #292929;
  }
}
