@import "../../../styles/variables";

.popup {
  // Rest Stops panel
  &--route {
    .popup__title {
      background-color: $Info;

      .name {
        color: $White;
      }
    }
    .popup__content {
      padding: 0;
      @media (min-width: 768px) {
        padding: 1rem;
        overflow: hidden;
      }

      .route-details {
        padding: 12px 16px 16px;
        border-radius: 8px;
        border: 2px solid transparent;
        position: relative;
        @media (min-width: 768px) {
          &:before {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: "";
            box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.10), 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
            border-radius: 8px;
          }
        }

        &:hover {
          border: 2px solid transparentize($Blue90, 0.5);
          cursor: pointer;
        }
      
        &.selected {
          border: 2px solid $Info;
        }
      
        &:focus {
          box-shadow: 0 0 0 0.25rem rgba(1, 51, 102, 0.25);
          outline: none;
          border: 2px solid $Focus;
        }
      
        &.mobile {
          border: none;
          box-shadow: none;
          padding: 1rem;
        }
      }
    }
  }
}
