//Type
$Type-Primary: #2D2D2D;
$Type-Secondary: #474543;
$Type-Disabled: #9F9D9C;
$Type-Link: #1A5A96;

//Dividers & Border
$Divider: #D8D8D8;
$Input-border: #8A8886;

//BC Theme
$BC-Blue: #013366;
$BC-Blue-Alt: #38598A;
$BC-Blue-Light: #F6F9FC;
$BC-Yellow: #FCBA19;
$BC-Yellow-Alt: #FDDC8C;
$BC-Yellow-Hover: #E8C061;
$BC-Yellow-Dark: #644A0A;

//Error & Status
$Info: #053662;
$Success: #2E8540;
$Error: #CE3E39;
$Warning: #F8BB47;
$Focus: #2E5DD7;

//Greys
$White: #FFFFFF;
$Grey10: #FAF9F8;
$Grey20: #F3F2F1;
$Grey30: #ECEAE8;
$Grey40: #E0DEDC;
$Grey50: #D1CFCD;
$Grey60: #C6C5C3;
$Grey70: #9F9D9C;
$Grey80: #605E5C;
$Grey90: #3D3C3B;
$Grey100: #353433;
$Grey110: #252423;
$Black: #000000;
$Grey-invert: #EDEBE9;

//Blue
$Blue10: #F1F8FE;
$Blue20: #D8EAFD;
$Blue30: #C1DDFC;
$Blue70: #5595D9;
$Blue80: #3470B1;
$Blue90: #1E5189;

//Yellow
$Gold10: #FFFEE0;
$Gold20: #FEF0D8;
$Gold30: #FDE9C4;
$Gold40: #fcf9d1;
$Gold50: #FAF4A6;
$Gold60: #FBD389;
$Gold80: #E5d606;
$Gold110: #584215;


//custom colours for DriveBC
$HwyCrest: #1E53A7;
$HwyCrest-green: #00674E;
$Chainups: #F6E24B;
$Red-dark: #9C2024;

$Surface-tinted: #FBFDFF;

$Surface-camera: #F1F8FE; //same as $Blue10
$Surface-major: #F4E1E2;
$Surface-minor: #FEF0D8;
$Surface-ferry: #FBFFFC;
$Surface-weather: #ECEAE8; //same as $Grey30
$Surface-reststop: #E8EAF4;

$Type-reststop: #273F94;

//colours for alternate routes
$Distance-label-index: #3262AD;
$Distance-label-hover: #5386ED;

$Nav-bar-btn-text: #292929;
$Nav-link-border: #D9D9D9;

// Button states
$BtnHover-Primary: $Blue90;
$BtnActive-Primary: $BC-Blue;

$BtnHover-Secondary: $Grey-invert;
$BtnActive-Secondary: $White;

$BtnHover-Tertiary: $Grey30;
$BtnActive-Secondary: $White;


//Animations
$Anim-gentle: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$Border-dark: #353433;

$GreyOnBlue: #5C6B78;

// My Account
$MyAccountHeader: #323130;
$MyAccountSuccess: #E5F5E7;
$MyAccountSuccessText: #2E8540;
$MyAccountWarning: #FEF8E8;
$MyAccountWarningText: #584215;