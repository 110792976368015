@import "../styles/variables.scss";

.not-found-page {
  .text-container {
    h1 {
      margin-bottom: 15px;
    }

    // Font
    color: $BC-Blue;

    // Size
    max-width: 30rem;
  }
}
