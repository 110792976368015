@import "../../styles/variables";

.camera-list {
  margin-top: 32px;
  
  .highway-group {
    &:not(:first-of-type) {
      margin-top: 4rem;
    }

    .highway-title {
      margin-bottom: 1em;
      display: flex;
      align-items: center;

      .highway-shield-box {
        padding: 13px 0;
        padding-right: 12px;
        border-right: 1px solid $Grey40;
        & + .highway-name {
          margin-left: 12px;
        }

        .highway-shield {
          position: relative;

          &__number {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin-top: 4px;
            color: $HwyCrest;
            font-weight: 700;
            font-size:  0.938rem;
          }

          img {
            height: 40px;
          }
        }
      }
      .highway-name {

        &__number, &__alias {
          margin-bottom: 0;
        }
        &__alias {
          color: $HwyCrest;
          &--green {
            color: $HwyCrest-green;
          }
        }
      }
    }
  }

  .webcam-group {
    display: grid;
    column-gap: 1.5em;
    row-gap: 3.5em;
    grid-template-columns: repeat(1, 1fr );
    grid-template-rows: auto;

    @media (min-width: 576px) {
      grid-template-columns: repeat(2, 1fr );
    }

    @media (min-width: 1240px) {
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }

    @media (min-width: 1620px) {
      grid-template-columns: repeat(4, 1fr );
    }
  }
}