@import "../../../styles/variables";

.navbar {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	z-index: 20;
	background: white;
	padding: 0;
	box-shadow: rgb(0,0,0,0.2) 0px 2px 10px 0px;

	&:has(.navbar-collapse.show) {
		@media (max-height: 600px) {
			height: calc(100%);
		}
	}

	.container {
		display: flex;
		justify-content: space-between;
		max-width: unset;
		padding: 0;

		&:has(.navbar-collapse.show) {
			@media (max-height: 600px) {
				height: 100%;
			}
		}

		@media (min-width: 992px) {
			margin: 0 4rem;
		}

		.header-left, .header-right {
			display: flex;
			align-items: center;

			@media (max-width: 1399px) {
				width: 100%;
				padding: 5px 10px;
			}
		}

		.header-right {
			.btn {
				white-space: nowrap;
			}
		}

		.navbar-collapse.show {
			@media (max-height: 600px) {
				height: calc(100% - 58px);
			}

			.navbar-nav {
				@media (max-height: 600px) {
					height: 100%;
					overflow: auto;
				}
			}
		}
	}

	h2 {
		margin-bottom: 0;
	}

	&-toggler {
		height: 3rem;
		width: 3rem;
		padding: 0.5rem;
		display: flex;
		flex-direction: column;
		justify-content: space-evenly;
		align-items: center;
		border: none;

		.line {
			background-color: $Type-Primary;
			width: 1.6rem;
			height: 0.125em;
		}

		.line1 {
			transform-origin: 0% 0%;
			transition: transform 0.4s $Anim-gentle;
		}

		.line3 {
			transform-origin: 0% 100%;
			transition: transform 0.4s $Anim-gentle;
		}

		&:not(.collapsed) {
			.line {
				margin-left: 8px;
			}

			.line1 {
			display: block;
			transform: rotate(45deg);
			}

			.line2 {
				opacity: 0;
			}

			.line3 {
				display: block;
				transform: rotate(-45deg);
			}
		}

		&:focus {
			box-shadow: none;
		}

		&-icon {
			background-image: none;
		}
	}

	.nav-divider {
		border-right: 1px solid $Divider;
		margin: 0 12px;
		height: 38px;
		display: none;

		@media (min-width: 992px) {
			display: block;
		}
	}

	&-brand {
		padding: 0;
		margin: 0;

		.header-logo {
			width: 126px;
			height: 32px;
			top: 6px;
			left: 32px;
		}

		& + .nav-divider {
			margin: 0 8px 0 0;
		}
	}

	&-collapse {
		order: 1;
		background: $Grey10;
		padding: 0;

		@media (min-width: 992px) {
			order: unset;
			background: unset;
		}

		.navbar-nav {
			.nav-link {
				color: $Type-Primary;
				padding: 16px;
				border-bottom: 1px solid $Nav-link-border;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: space-between;

				@media (min-width: 992px) {
					padding: 16px 8px;
					border: 2px solid transparent;
				}

				&:hover {
					text-decoration: none;
					background-color: hsla(0,0%,7%,.15);
					svg {
						transition: 0.2s ease-in-out all;
						left: 0;
						position: relative;
					}
				}

				&:focus {
					border: rgb(46, 93, 215) solid 2px;
					background-color: hsla(0, 32%, 83%, 0.15);
				}

				&:active, &.active {
					font-weight: bold;
					color: rgb(46, 93, 215);
					@media (min-width: 992px) {
						color: $Type-Primary;
						font-weight: normal;
						border-bottom: rgb(46, 93, 215) solid 2px;
					}
				}

				.title {
					display: flex;
					align-items: center;

					.unread-count {
						font-size: 10px;
						font-weight: 700;
						padding: 0 9px;
						color: white;
						background-color: $BC-Blue;
						border-radius: 12px;
						margin-left: 8px;
						display: flex;
						align-items: center;
						height: 20px;
					}
				}

				svg {
					font-size: 1.25rem;
					position: relative;
					left: -4px;
					transition: 0.2s ease-in-out left;
				}
			}

			.footer-nav-link {
				font-size: 0.875rem;
				border: none;
				display: inline-block;
				padding: 12px 0;
				margin: 0 16px;
				width: max-content;
				&:hover {
					background: transparent;
					text-decoration: underline;
				}

				&:active, &.active {
					border-bottom: none;
				}

				&:after {
					content: none;
				}
				&.first-link {
					margin-top: 8px;
				}
			}

			.filler {
				height: 100vh;
			}
		}
	}

	.header-right__btn {
		margin-left: auto;
		margin-bottom: 0;
		font-weight: 400;

		&:not(:first-child) {
			margin-left: 16px;
			margin-right: 0;
		}

		svg {
			margin-right: 8px;
			color: $Nav-bar-btn-text;
		}

		&:hover {
			text-decoration: none;
		}

		& + .nav-divider {
			display: none;

			@media (min-width: 768px) {
				display: block;
			}
		}
	}

	#feedback-btn {
		&::after {
			content: none;
		}
	}
}
