@import "../../styles/variables.scss";

.routing-caption {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 0.75rem;
}

.routing {
  padding: 1rem 1rem 0;

  .routing-container {
    position: relative;
    z-index: 5;

    .swap-button{
      position: absolute;
      top: calc(50% - 20px);
      right: -13px;
      padding: 8px 4px 8px 4px;
      color: $Type-Link;
      border-radius: 32px 32px 32px 32px;
      border: 1px solid #D9D9D9;
      background: $White;
      margin-bottom: 0;

      &:hover {
        background: $Type-Link;
        color: $White;
      }
    }

    .typeahead-container {
      position: relative;
      width: 100%;

      @media (min-width: 768px), (orientation: landscape) {
        width: 340px;
      }

      .typeahead-spinner {
        position: absolute;
        top: -0.5rem;
        right: 1.25rem;
      }

      .location-marker {
        position: absolute;
        z-index: 1;
        margin: 0.25rem;

        &--from {
          font-size: 0.75rem;
          margin: 0.6875rem 0.75rem;
          color: $Type-Secondary;
        }

        &--to {
          font-size: 1rem;
          margin: 0.5rem 0.75rem;
          color: $Error;
        }
      }

      .clear-btn {
        position: absolute;
        top: 0;
        right: 0;
        background: none;
        border: none;
        font-size: 0.75rem;
        padding: 0.75rem 1.5rem 0.75rem 0.5rem;
      }

      #location-search-typeahead {
        font-size: 0.875rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-color: $Blue10;
        transform: translate3d(0px, 36px, 0px) !important;
      }

      &.stacked {

        .form-control {
          padding: 0.625rem 2.5rem 0.625rem 2.25rem;

          &.focus {
            z-index: 1;
          }
        }

        &.typeahead-container--from {
          .form-control {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: 0;
          }
        }

        &.typeahead-container--to {
          .form-control {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
          }
        }
      }
    }
  }
}
