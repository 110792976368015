@import "../../styles/variables.scss";

.friendly-time {
  position: relative;
  width: fit-content;

  &:has(.friendly-time__tooltip) {
    text-decoration: underline;
    cursor: pointer;

    time:hover {
      color: $Grey70;
      text-decoration: underline;
    }
  }

  &__tooltip {
    font-size: 0.75rem;
    visibility: hidden;
    word-wrap: break-word;
    background-color: $Grey90;
    color: $White;
    text-align: center;
    border-radius: 4px;
    padding: 8px;
    width: fit-content;
    white-space: nowrap;
    z-index: 1;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: 7px;

    &:before {
      content: "";
      border-right: 7px solid transparent;
      border-left: 7px solid transparent;
      border-bottom: 7px solid $Grey90;
      position: absolute;
      top: initial;
      left: 50%;
      bottom: 100%;
      transform: translate(-50%, 0);
      margin-left: 7px;
    }

    &.showTooltip {
      visibility: visible;
    }
  }
}

.friendly-time-text {
  margin-bottom: 0;
}
