@import "../../styles/variables";

.highway-filters {
  position: absolute;
  top: 40px;
  width: 250px;
  height: 350px;
  right: 0;
  z-index: 12;
  background: white;
  overflow-y: hidden;
  box-shadow: 0px 1.937px 4.358px 0px rgba(0, 0, 0, 0.13), 0px 0.363px 1.089px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  flex-direction: column;

  @media (max-width: 575px) {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .search-container {
    padding: 0.75rem;
    background: $Grey10;

    @media (min-width: 768px) {
      order: unset;
      margin-top: 0;
    }

    .rbt-menu {
      display: none !important;
    }

    .search-icon {
      position: absolute;
      z-index: 1;
      left: 22px;
      top: 23px;
      color: $Type-Disabled;
    }

    .form-control {
      border-radius: 4px;
      font-size: 0.875rem;
    }

    input {
      padding: .5rem .5rem .5rem 2.2rem;
    }
  }

  .selected-filter-container {
    border: 1px solid #e5e5e5;
    padding: 8px;

    .selected-filter {
      background: $Info;
      border-radius: 4px;
      padding: 8px;
      color: white;
      font-size: 0.875rem;

      .remove-btn {
        cursor: pointer;
        font-weight: bold;
        font-size: 0.875rem;
        color: white;
      }
    }

    &.no-selection {
      color: $Type-Disabled;
      font-size: 0.875rem;
      padding: .5rem 1rem;
    }
  }

  .highway-options {
    position: relative;
    overflow-y: auto;
    padding: 0.25rem;

    .highway-row {
      padding: .5rem 0.8rem;
      border-radius: 4px;
      font-size: 0.875rem;
      cursor: pointer;

      &:hover {
        background-color: $Grey10;
      }
    }
  }
}
