@import "../styles/variables.scss";

.bulletin-page {
  position: relative;

  .network-error, .server-error {
    @media (max-width: 768px) {
      position: relative;
    }
  }

  .page-header {
    .timestamp-container, .friendly-time-text {
      color: $Type-Link;
    }

    & + .container {
      padding-top: 24px;

      @media (min-width: 992px) {
        padding-top: 48px;
      }
    }
  }

  .cms-body {
    .responsive-object {
      img {
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
  }
}
