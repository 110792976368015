@import "../../styles/variables";

.invalid-feedback {
  display: flex;
  column-span: all;
  flex-direction: column;
}

.route-details {
  margin-bottom: 1rem;

  .form-check-input {
    position: static;
  }

  .route-title {
    .route-tools {
      .route-index {
        height: 24px;
        width: 24px;
        border-radius: 12px;
        border: 1px solid $Info;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 0.875rem;
        font-weight: 700;
        margin-bottom: 4px;
        background-color: $White;
        color: $Info;

        &.selected {
          background-color: $Info;
          color: $White;
        }
      }

      .notifications-settings {
        display: flex;
        align-items: center;

        .notifications-toggle {
        }

        svg {
          margin-left: 8px;
          color: $GreyOnBlue;
          cursor: pointer;
        }
      }

      .favourite-btn.text-only-btn {
        margin: 0;
        padding: 0;
        background: none;
        z-index: 10;

        svg {
          font-size: 16px;
        }
      }
    }

    .card-img-box {
      overflow: hidden;
      border-radius: 4px;
      position: relative;
      cursor: pointer;

      .overlay-screen {
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: rgba(0, 0, 0, 0.6);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;

        &__text {
          font-weight: 700;
          color: white;

          svg {
            margin-right: 8px;
          }
        }
      }

      &:hover {
        .overlay-screen {
          opacity: 1;
        }
        .card-img {
          transform: scale(1.25);
          background: rgba(0,0,0,.5);
          background-blend-mode: overlay;
        }
      }
      .card-img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border-radius: 0;
        transition: all 0.3s ease;
      }
    }

    .route-name-and-distance {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      gap: 10px;
      margin-top: 1rem;

      h4, p {
        margin-bottom: 0;
      }
      h4 {
        font-size: 1.125rem;
      }

      .route-distance {
        font-size: 1rem;
        color: $Grey80;
        white-space: nowrap;
      }
    }
  }
  .route-disclosures {
    display: flex;
    width: fit-content;
    margin-top: 16px;

    .route-pill {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      padding: 8px 16px;
      font-weight: 700;
      font-size: 0.875rem;
      width: 100%;

      @media (min-width: 768px) {
        border-radius: 24px;
        padding: 4px 12px;
        width: auto;
      }

      .route-item__icon {
        margin-right: 8px;
      }

      .route-item__count {
        margin-right: 0.5ch;
      }

      &--closures {
        background: $Error;
        color: white;
      }

      &--advisories {
        background: #FEF0D8;
        color: $Gold110;
      }

      &:first-of-type {
        margin-right: 12px;
      }
    }
  }

  .route-items {
    margin-top: 16px;

    .route-item {
      padding: 4px 0;

      &__count {
        display: inline-block;
        width: 2ch;
        font-weight: 700;
        margin-left: 12px;
      }

      &__icon {
        margin-left: 8px;
        margin-right: 8px;
      }

      &--closures {
        border-left: 2px solid $Error;
        color: $Error;
        font-weight: 700;
      }

      &--advisories {
        border-left: 2px solid $Gold110;
        color: $Gold110;
        font-weight: 700;
      }

      &--major {
        border-left: 2px solid $Error;
      }

      &--minor {
        border-left: 2px solid $Warning;
      }

      &--roadConditions {
        border-left: 2px solid $Warning;
      }

      &--chainUps {
        border-left: 2px solid $Chainups;
      }

      &--ferries {
        border-left: 2px solid $HwyCrest-green;
      }
    }
  }

  .viewCams-btn {
    font-size: 0.875rem;
    font-weight: 700;
    color: $Type-Link;
    border: none;
    background: none;
    padding: 0;
    margin-top: 12px;

    svg {
      margin-right: 8px;
    }

    &.disabled {
      text-decoration: unset;
      color: $Grey70;
    }
  }
}

.modal-dialog {
  .route-nickname {
    display: flex;
    align-items: center;

    p {
      margin-bottom: 0;
    }

    svg {
      margin-left: 4px;
    }
  }
}


.modal--notifications-settings {

  &.long {
    .modal-dialog {
      @media (min-width: 576px) {
        margin-top: 0;
      }
    }
  }

  .modal-dialog {
    margin: 0;

    @media (min-width: 576px) {
      max-width: 650px;
      margin-top: 15vh;
      margin-left: auto;
      margin-right: auto;
    }

    .modal-content {
      height: fit-content;

      @media (max-width: 575px) {
        height: 100vh;
      }

      &:has(.specific-time-dates) {
        height: 100vh;
      }

      .modal-header {
        @media (max-width: 575px) {
          position: fixed;
          top: 0;
          width: 100%;
        }
      }

      .modal-body {
        padding-bottom: 12px;
        overflow: auto;

        @media (max-width: 575px) {
          margin: 60px 0;
        }
      }

      .modal-footer {
        position: fixed;
        bottom: 0;
        width: 100%;
      }
    }

    h3 {
      font-size: 1.25rem;
    }

    p {
      margin-bottom: 0.375rem;
    }

    .info-row {
      margin-top: 12px;

      &__data {
        display: flex;

        .edit {
          color: $Type-Link;
          margin-left: 24px;

          svg {
            margin-right: 8px;
          }
        }
      }
    }

    .notifications-section {
      div:has(.form-check) {
        display: flex;
        align-items: baseline;

        svg {
          margin-left: 8px;
        }
      }
    }

    .double-picker {
      flex-direction: column;
      align-items: flex-start;

      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }

      .spacer {
        margin-left: 8px;
        margin-right: 8px;
        display: none;

        @media (min-width: 768px) {
          display: block;
        }
      }
    }

    .modal-footer {
      justify-content: flex-start;
      border-top: 1px solid #D9D9D9;

      @media (min-width: 768px) {
        display: block;
      }

      .btn {
        margin: 0;

        &.cancel-btn {
          margin: auto;

          @media (min-width: 768px) {
            margin-left: 1rem;
          }
        }

        svg {
          margin-left: 8px;
        };
      }
    }
  }
}
