@import "../../styles/variables.scss";
.advisories-list {
  list-style-type: none;
  padding-left: 0;

  .advisory-li {
    margin-bottom: 1rem;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 12px;
    border-radius: 4px;
    background: white;
    box-shadow: 0px 0.6px 1.8px 0px rgba(0, 0, 0, 0.10), 0px 3.2px 7.2px 0px rgba(0, 0, 0, 0.13);
    text-decoration: none;

    &::marker {
      color: $Type-Link;
    }

    &:hover {
      .advisory-li-title {
        text-decoration: underline;
      }
    }

    &__content {
      .advisory-li-title-container {
        .advisory-li-title {
          color: $Type-Link;
          font-size: 1.5rem;
          font-weight: 700;
          line-height: 1.5;
          margin-bottom: 0.25rem;

          &-container {
            width: 100%;
          }
        }
      }
    }

    &__arrow {
      margin-left: auto;
      color: $Gold110;
    }
  }

  .timestamp-container {
    display: flex;
    align-items: center;

    .advisory-li-state, .friendly-time, .formatted-date {
      margin-left: 0.5ch;
    }

    .friendly-time, .formatted-date {
      margin-left: 0.5ch;
    }

    .friendly-time-text, .formatted-date {
      color: $Grey80;
      font-size: 0.875rem;
      line-height: 1.5;
    }

    .unread-display {
      height: 8px;
      width: 8px;
      background-color: $Error;
      border-radius: 50%;
      display: inline-block;
      margin-right: 5px;
    }
  }

  .timestamp-container--mobile {
    display: none;
  }
}

.empty-advisory-display {
  h2 {
    margin-bottom: 2rem;
    color: $BC-Blue;
  }
}

.body-skeleton {
  @media (min-width: 768px) {
    width: 600px;
  }
  @media (max-width: 768px) {
    width: 320px;
  }
}

.hidden-desktop {
  @media (min-width: 768px) {
    display: none;
  }
}

.hidden-mobile {
  @media (max-width: 768px) {
    display: none;
  }
}
