@import "../styles/variables.scss";

.cameras-page {
  position: relative;

  .network-error, .server-error {
    @media (max-width: 767px) {
      position: relative;
    }
  }

  .controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $Grey10;
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 8px;

    @media (min-width: 1200px) {
      justify-content: space-between;
    }

    .route-display-container {
      display: flex;
      align-items: center;

      @media (max-width: 767px) {
        width: 100%;
      }

      .routing-container {
        position: relative;
        padding: 0;

        @media (max-width: 767px) {
          width: 100%;
        }

        .swap-button {
          position: absolute;
          top: 22px;
          right: -10px;
        }
      }
    }

    .camSearch-container {
      width: 100%;
      order: 2;
      margin-top: 10px;

      .rbt {
        .rbt-input {
          height: 41px;
          font-size: 0.875rem;
        }

        .clear-btn {
          position: absolute;
          top: 0;
          right: 0;
          background: none;
          border: none;
          font-size: 0.75rem;
          padding: 0.75rem;
        }
      }

      @media (min-width: 768px) {
        order: unset;
        margin-top: 0;
        margin-left: 20px;
        margin-right: 20px;
        max-width: 400px;
      }

      @media (min-width: 1200px) {
        margin-left: 0;
        margin-right: 0;
      }

      .rbt-menu {
        display: none !important;
      }
    }
  }

  .selected-filters-container {
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-size: 0.75rem;

    .filtering-by {
      margin-right: 10px;
    }

    .selected-filter {
      display: flex;
      background: $Info;
      border-radius: 8px;
      padding: 8px 8px 6px;
      color: white;

      .remove-btn {
        cursor: pointer;
        font-weight: bold;
        font-size: 0.875rem;
        color: white;
        margin-left: 8px;
        margin-right: 4px;
      }
    }
  }

  .empty-cam-display {
    h2, h6 {
      color: $Info;
    }

    h6 {
      margin-top: 2rem;
    }
  }
}
